/* this file is transformed by vux-loader */
/* eslint-disable */
// import { getTestTable } from "../../api/index";

import { unique } from "@/assets/app.js";
import TzryInfo from "./TzryInfo";
export default {
  components: {
    TzryInfo
  },
  props: {
    yxryList: {
      default: []
    }
  },
  data() {
    return {
      ylist: this.yxryList,
      yselectsId: []
    };
  },
  created() {
    // let ylist = [];
    // ylist = this.unique(this.yxryList);
    // this.ylist = ylist;
    // console.log(this.ylist)
  },
  methods: {
    unique(arr) {
      const res = new Map();
      return arr.filter(arr => !res.has(arr.USER_ID_) && res.set(arr.USER_ID_, 1));
    },
    onDelect() {
      let ylist = [];
      this.yselectsId.map(id => {
        for (let i = 0; i < this.ylist.length; i++) {
          if (id == this.ylist[i].USER_ID_) {
            this.ylist.splice(i, 1);
          }
        }
      });
    },
    onySelects() {
      this.$emit("delectAfter", this.ylist);
    }
  }
};