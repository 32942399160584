/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
// import { getTestTable } from "../../api/index";

import { unique } from "@/assets/app.js";
import TzryInfo from "./TzryInfo";
import FwTzrySelect from "./fwTzrySelect";
export default {
  components: {
    FwTzrySelect
  },
  data() {
    return {
      showInfo: false,
      showySlect: false,
      ryName: "",
      data: [],
      mount: [],
      mountDupRe: [],
      pageNum: 1,
      prePage: 1,
      maxPageNum: 1,
      totalNum: 0,
      // pageIndex: 0,
      pageSize: 20,
      result: [],
      ySelectList: [],
      yxryList: [],
      itemString: "",
      ryInfoData: {}
    };
  },
  created() {
    this.getCgwpInfo();
    this.data = this.mount;
    this.onEnter();
  },
  mounted() {},
  methods: {
    getCgwpInfo() {
      var pageNum = this.pageNum - 1;
      var url = _baseUrl + `/dev/cus/customData/getUserList/getData.do?single=false&_winid=w8112&_t=658474&pageIndex=${pageNum}&pageSize=${this.pageSize}`;
      this.$ajax.post(url).then(res => {
        this.data = res.data.data;
        this.data.map(item => {
          if (JSON.stringify(this.mount).indexOf(item.USER_ID_) == -1) {
            this.mount.push(item);
          }
        });
        this.totalNum = res.data.total;
        this.maxPageNum = Math.ceil(this.totalNum / 10);
      });
    },
    onPlus() {
      this.pageNum += 1;
      this.getCgwpInfo();
    },
    onMinus() {
      this.pageNum -= 1;
      this.getCgwpInfo();
    },
    onEnter() {
      document.onkeydown = e => {
        if (e.keyCode === 13) {
          var pageNum = this.pageNum;
          var maxPageNum = this.maxPageNum;
          if (pageNum <= maxPageNum) {
            // this.prePage = pageNum;
            this.getCgwpInfo();
          } else {
            Toast("请正确输入页数！");
          }
        }
      };
    },
    tzrysInfo(item) {
      this.showInfo = true;
      this.ryInfoData = item;
    },
    onSearch() {
      document.onkeydown = e => {
        if (e.keyCode === 13) {
          let url = _baseUrl + "/dev/cus/customData/getUserList/getData.do?single=false&_winid=w225&_t=137534&Q_FULLNAME__S_LK=" + this.ryName + "&pageIndex=0&pageSize=10000";
          this.$ajax.post(url).then(res => {
            this.data = [];
            this.data = res.data.data;
            this.data.map(item => {
              if (JSON.stringify(this.mount).indexOf(item.USER_ID_) == -1) {
                this.mount.push(item);
              }
            });
            this.totalNum = res.data.total;
            this.maxPageNum = Math.ceil(this.totalNum / 10);
            console.log(this.mount.length);
          });
        }
      };
    },
    onCancel() {
      this.ryName = "";
      this.pageNum = 1;
      this.getCgwpInfo();
    },
    //已选
    onSelectBnt() {
      this.yxryList = this.onySelect();
      this.showySlect = true;
      // console.log(this.yxryList)
    },

    delectAfter(items) {
      this.result = [];
      items.map(item => {
        this.result.push(item.USER_ID_);
      });
      this.showySlect = false;
    },
    onySelect() {
      this.ySelectList = [];
      this.mount.map(item => {
        this.result.map(USER_ID_ => {
          if (USER_ID_ == item.USER_ID_) {
            this.ySelectList.push(item);
          }
        });
      });
      return this.ySelectList;
    },
    //确定
    onSelects() {
      let ySelectList = this.onySelect();
      this.$emit("ryList", ySelectList);
    }
  }
};