/* this file is transformed by vux-loader */
/* eslint-disable */
import { format } from "../../../assets/app";
export default {
  props: {
    tzryInfoProps: {
      default: {}
    }
  },
  data() {
    return {
      tzryInfo: {}
    };
  },
  created() {
    let infoData = this.tzryInfoProps;
    if (infoData.SEX_ == "Male") {
      infoData.SEX_ = "男";
    } else {
      infoData.SEX_ = "女";
    }
    infoData.BIRTHDAY_ = format(infoData.BIRTHDAY_);
    this.tzryInfo = infoData;
  }
};